import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import HolaMatic from "../../contracts/HolaMatic.json";
import getWeb3 from "../../getWeb3";
const defaultAmountArr = ['50','100','250','500','1000'];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      totalInvested:0,
      totalUsers:0,
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
      amountBNB:null,
      user_referer:'-----------',
      balance:null,
      adminFee:null,
      adminFee_percentage:5,
      reinvest_percentage:0,
      withdrwal_percentage:0,
      //GetDownlineIncomeByUserId:0,
      //GetUplineIncomeByUserId:0,
      communityLevels:[],
      sponsorList:[],
      _userUplineIncome:0,
      _userDownlineIncome:0,
      package_index:0,
      geteligibleFor:0
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = HolaMatic.networkId;
        const instance = new web3.eth.Contract(
          HolaMatic.abi,
          deployedNetwork && HolaMatic.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:HolaMatic.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;
    
    //console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    let userInfo = await contract.methods.users(accounts).call();    
    userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
    userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
    userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
    userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
    userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
    userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
    let user_referer = '---------';
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
    }

    
    
    let TotalBonus = await contract.methods.TotalBonus(accounts).call();
    
    TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
    TotalBonus = parseFloat(TotalBonus).toFixed(5);
    let totalWithdrawn = await contract.methods.totalWithdrawn().call();
    totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
    totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
    let totalDeposits = await contract.methods.totalDeposits().call();
    totalDeposits = parseFloat(totalDeposits).toFixed(5);
    let totalInvested = await contract.methods.totalInvested().call();
    totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
    totalInvested = parseFloat(totalInvested).toFixed(5);
    let totalUsers = await contract.methods.totalUsers().call();
    
    
    let geteligibleFor = await contract.methods.geteligibleFor(accounts).call();
    geteligibleFor = this.state.web3.utils.fromWei(geteligibleFor, 'ether');
    geteligibleFor = parseFloat(geteligibleFor).toFixed(5);
    

    let balance = TotalBonus;
    let adminFee = balance*this.state.adminFee_percentage/100;
    balance = balance - adminFee;


    let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
    let reinvest_percentage = getEligibleWithdrawal.reivest;
    let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

    let GetDownlineIncomeByUserId = await contract.methods.getCurrentDownlineIncomeByUserId(accounts).call();
    GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
    GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

    let GetUplineIncomeByUserId = await contract.methods.getCurrentUplineIncomeByUserId(accounts).call();
    GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
    GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);
    

     
    this.setState({ 
      userInfo,
      TotalBonus,
      totalWithdrawn,
      totalDeposits,
      totalInvested,
      totalUsers,
      //networkMain:true,
      YourAddress:YourAddress,
      YourAddressfull:YourAddressfull,
      user_referer:user_referer,
      balance,
      adminFee,
      reinvest_percentage,
      withdrwal_percentage,
      GetUplineIncomeByUserId,
      GetDownlineIncomeByUserId,
      geteligibleFor,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      // let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
      let uplineCount = 30;//getEligibleLevelCountForUpline.uplineCount;
      let downlineCount = 20;//getEligibleLevelCountForUpline.downlineCount;
      let communityLevels = [];
      let upline_users = [];
      let downline_users = [];
      let current_user = accounts;
      let userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=uplineCount;i++){          
        if(current_user == userInfo.singleUpline) continue;
        current_user = userInfo.singleUpline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
      }
      upline_users.sort((a, b) => b.level>a.level? 1 : -1);
      upline_users.map(function(val, index){
        communityLevels.push(val);
      });
      let currentUserInfo = await contract.methods.users(accounts).call();
      let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
      let income = (investment/100).toFixed(5);
      income = (0).toFixed(5);
      investment = parseFloat(investment).toFixed(5);
      let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
      communityLevels.push(main_user);
      current_user = accounts;
      userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=downlineCount;i++){
        if(current_user == userInfo.singleDownline) continue;
        current_user = userInfo.singleDownline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
      }
      downline_users.map(function(val, index){
        communityLevels.push(val);
      });

      let sponsorList = [];
      let count = 0;
      for(let i=0;i<10;i++){
        let referral_stage = await contract.methods.referral_stage(accounts,i).call();
         let requiredDirect = await contract.methods.requiredDirect(i).call();
        let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
        //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
        let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
        _investment = parseFloat(_investment).toFixed(5);
        percentage_amount = parseFloat(percentage_amount).toFixed(5);
        let _noOfUser = referral_stage._noOfUser;
        
        if(i == 0){
          count = _noOfUser;
        }
        let status = '';
        let requireText = '';
        if(count<requiredDirect){
          status = 'Unqualified';
          requireText = 'require '+requiredDirect + ' direct';
        }
        sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
      }
      
      //this.setState({sponsorList});
      this.setState({communityLevels, sponsorList});
      // console.log(communityLevels);      
      // console.log(sponsorList);       
    });     
  }

  doJoinNow = async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    let userInfo = await contract.methods.users(accounts).call();
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      sponsor_address = userInfo.referrer;
    }
    else if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amountBNB){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amountBNB;
    if(sponsor_address){
      if(balanceEthVal >= amountBNB){
        try {
          this.setState({loading:true});          

          let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
          let invest = await this.state.contract.methods.invest(sponsor_address,this.state.package_index).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          if(invest.status){
            this.props.enqueueSnackbar("invest Success!",{ variant: 'success' });  
          }else{
            this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });  
          }
          this.setState({loading:false});
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
      }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doWithdrawal = async () => {
    if(this.state.TotalBonus > 0){
      this.setState({loading:true});      

      let withdrawal = await this.state.contract.methods.withdrawal().send(
        { 
          from: this.state.accounts
        }
      );

      if(withdrawal.status){
        this.props.enqueueSnackbar("Withdrawl Success!",{ variant: 'success' });  
      }else{
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });  
      }
      this.setState({loading:false});
    }else{
      this.props.enqueueSnackbar('Insufficient balance!',{ variant: 'error' });
    }
         
  }

  render() {
    return (
      <Frame withHeader={true} withFooter={true} contractAddress={this.state.contractAddress}>        
        {this.state.loading ? (
          <div className="loader-container">
            <div className="loader-logo">
              <div className="loader-circle"></div>
            </div>
          </div>
        ) : (null)}

        <section className="banner_section position-relative">
          <div className="container">
            <div className="banner_box">
              <div className="banner_content">
                <h2>Build Wealth! </h2>
                <div className="banner_paragraph d-flex">
                  <h1>Matic</h1>
                  <p>Holamatic is your complete end-to-end solution for systematic & tailored trading opportunities.</p>
                </div>
                <h2> Investment</h2>
              </div>
            </div>
          </div>
          <img className="matic_icon" src="img/matic_icon.png" alt="" />
          <img className="banner_icon1" src="img/mark-header-04.png" alt="" />
          <img className="banner_icon2" src="img/mark-header-06.png" alt="" />
          <img className="banner_icon3" src="img/mark-header-03.png" alt="" />
        </section>        

        <div className="container">
          <hr className="m-0" />
        </div>

        <section className="pt-5 pb-5">
          <div className="container-fluid">
            <div className="gradient_bg position-relative">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="heading mb-4">
                    <span>ID - {this.state.YourAddress}</span>
                    <h2>GLOBAL <br /> DETAILS</h2>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Community Level <span>Upline Income</span></h4>
                        <h3>{this.state.GetUplineIncomeByUserId} MATIC</h3>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Community Level <span>Downline Income</span></h4>
                        <h3>{this.state.GetDownlineIncomeByUserId} MATIC</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Affiliate Level <span>Sponsor Income</span></h4>
                        <h3>{this.state.userInfo.referrerBonus} MATIC</h3>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Referred by</h4>
                        <h3>{this.state.user_referer}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Lifetime Income</h4>
                        <h3>{this.state.geteligibleFor} MATIC</h3>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="middle_container">
              <div className="main_heading mb-4">
                <h2>Join with <span>Us now</span></h2>
              </div>
              
              <div className="form-group">
                {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                  <input className="cus_input" type="text" placeholder="Sponsor Address"
                    readOnly
                    value={this.state.user_referer || ''}
                    onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                  />
                ):(
                  <input className="cus_input" type="text" placeholder="Sponsor Addresss"
                    value={this.state.sponsor_address || ''}
                    onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                  />
                )}
              </div>
              <ul className="trx_btn">
                {defaultAmountArr.map((amount,key) => {return(
                  <li key={key}>
                    <button className={this.state.amountBNB==amount?'active':'inactive'}
                      onClick={e => this.setState({amountBNB:amount,package_index:key})}>
                      {amount+' MATIC'}
                    </button>
                  </li>
                  )
                })}
              </ul>
              <div className="text-center"> 
                  <button disabled={this.state.loading} onClick={this.doJoinNow} className="theme_btn btn_big">Join Now</button>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="heading mb-4">
                  <img className="wallet_matic_img" src="img/matic_icon3.png" alt="" />
                  <h2>Wallet Balance</h2>
                </div>
                <p>Holamatic wallet offers a seamless payment solution to make payments and interact in the blockchain network. Once you register and open an account on the investment platform, you would automatically have a system-generated wallet. We have developed a wallet storage facility, helping store and monitor the balance of digital assets by storing public and private keys. </p>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="wallet_ballance_box">
                  <div className="Reinvestment_bg">
                    <img src="img/mark-header-02.png" alt="" />
                    <p className="mb-0">Reinvestment percentage : <strong>{this.state.reinvest_percentage}%</strong></p>
                    <p className="mb-0">Withdrawable percentage : <strong>{this.state.withdrwal_percentage}% </strong></p>
                  </div>
                  <div className="form-group">
                    <label>Available MATIC</label>
                    <input className="cus_input" type="text" readOnly value={this.state.balance || 0} />
                  </div>
                  <div className="form-group">
                    <label>Reinvestment Amount (MATIC)</label>
                    <input className="cus_input" type="text" readOnly value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5) || 0} />
                  </div>
                  <div className="form-group">
                    <label>Withdrawable Amount (MATIC)</label>
                    <input className="cus_input" type="text" readOnly value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5) || 0} />
                  </div>
                  <div className="text-center mt-4"> 
                    <button disabled={this.state.loading} onClick={this.doWithdrawal} className="dark_btn  btn_big btn-block">Submit</button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-3 pb-5">
          <div className="container">
            <div className="heading mb-4">
              <h2>Community Level</h2>
            </div>
            <div className="teble-box mb-5">
              <div className="teble-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>User ID</th>
                      <th>Investment</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.communityLevels.length ? (
                      this.state.communityLevels.length>0 ? (
                        this.state.communityLevels.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td className="col-3">{val.levelText}</td>
                              <td className="col-6">{val.username}</td>
                              <td className="col-3">{val.investment} MATIC</td>
                              {/* <td className="col-3">{val.income} MATIC</td> */}
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )} 
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="heading mb-4 mt-4">
              <h2>My sponsor List</h2>
            </div>
            <div className="teble-box">
              <div className="teble-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th> Level </th>
                      <th> Count </th>
                      <th> Invest Amount </th>
                      <th> Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sponsorList.length ? (
                      this.state.sponsorList.length>0 ? (
                        this.state.sponsorList.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`sl${index}`} className={class_name}>
                              <td className="col-3"> Level-{val.level} <span style={{color:'#96d5cf'}}>{val.requireText?(`(${val.requireText})`):(null)}</span></td>
                              <td className="col-3">{val._noOfUser}</td>
                              <td className="col-3">{val._investment} MATIC</td>
                              <td className="col-3">{val.percentage_amount} MATIC 
                              {/* {val.status?(`(${val.status})`):(null)} */}
                              </td>
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="gradient_bg position-relative">
              <img className="matic_icon2" src="img/matic_icon2.png" alt="" />
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="heading mb-4">
                    <span>Reliable Investments</span>
                    <h2>Embedded investing solutions.</h2>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Transparent Investments</h4>
                        <p>Our decentralized Matic investment ecosystem is designed with a motto to raise capital and deliver the best.</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Asset Management</h4>
                        <p>Holamatic - A revolutionizing investment platform with strategized and cost-effective investment options.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>Huge Returns </h4>
                        <p>Highest returns with low-risk investment opportunities, a safer and smarter way to multiply your assets.</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="set_up_wallet">
                        <h4>No-Risk Involved</h4>
                        <p>Zero risks - simply invest on the platform without any limitations on earning from every Matic being invested.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pb-5">
          <div className="container">
            <ul className="pdf_ul">
              <li>
                <a href="#">
                  <img src="img/pdf_download.png" alt="" />
                  <span>English PDF</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="img/pdf_download.png" alt="" />
                  <span>Hindi PDF</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="img/pdf_download.png" alt="" />
                  <span>Vietnamese PDF</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="img/pdf_download.png" alt="" />
                  <span>Tamil PDF</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="img/pdf_download.png" alt="" />
                  <span>Chinese PDF</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="img/pdf_download.png" alt="" />
                  <span>French PDF</span>
                </a>
              </li>
            </ul>
          </div>
        </section>

        <div className="container">
          <hr className="m-0" />
        </div>

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="heading middle_heading position-relative mb-4">
              <h3>Copy link</h3>
              <h2>Your Referal Link</h2>
            </div>
            <div className="mt-5 mb-4 Referal_link text-center">
              
              <h2>https://<span>holamatic</span>.com/{this.state.YourAddress}</h2>
              <CopyToClipboard text={`https://holamatic.com/${this.state.YourAddressfull}`}
                      onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                      }>
                     <button className="theme_btn btn_big">Copy Link</button>
                    </CopyToClipboard> 
             
            </div>
          </div>
        </section>
        
      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));