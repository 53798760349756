import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { contractAddress } = this.props;
      return (
        <header>
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <div className="logo">
                  <a href="/">
                    <img src="img/logo.png" alt="" />
                  </a>
                </div>
              </div>
              <div>
                <a target={'_blank'} href={`https://polygonscan.com/address/${contractAddress}`} className="dark_btn btn_big">Verified Contract</a>
              </div>
            </div>
          </div>
        </header>
      );
    }
  }

  export default Header;